import React, { Fragment } from 'react'
import Link from 'gatsby-link'

import styled from 'styled-components'
import { grid } from 'styled-components-grid'

const PageLink = styled(({ className, url, children, active }) => {
  if (url) {
    return (
      <Link className={className} to={url}>
        <span>{children}</span>
      </Link>
    )
  }

  return (
    <div className={className}>
      <span>{children}</span>
    </div>
  )
})`
  background: ${props => props.theme.colors.aside.background};
  color: ${props => props.theme.colors.aside.text};
  display: flex;
  height: ${props => props.theme.dimen.breadcrumb.minimumSide};
  min-width: ${props => props.theme.dimen.breadcrumb.minimumSide};
  text-decoration: none;

  span {
    margin: auto;
    padding-left: ${props => props.theme.dimen.breadcrumb.x};
    padding-right: ${props => props.theme.dimen.breadcrumb.x};
  }
`

const ActivePageLink = styled(PageLink)`
  background: ${props => props.theme.colors.background('actionHover')};
  color: ${props => props.theme.colors.foreground('actionHover')};
`

const EllipsisLink = styled(({ className }) => (
  <span className={className}>
    <span>...</span>
  </span>
))`
  background: ${props => props.theme.colors.aside.background};
  color: ${props => props.theme.colors.foreground('main')};
  display: flex;
  height: ${props => props.theme.dimen.breadcrumb.minimumSide};
  min-width: ${props => props.theme.dimen.breadcrumb.minimumSide};
  text-decoration: none;

  span {
    margin: auto;
  }
`

const Paginator = styled(
  ({
    className,
    index,
    pageCount,
    width,
    nextLabel,
    previousLabel,
    pathPrefix,
    cursorComponent = ActivePageLink,
    pageComponent = PageLink,
    previousComponent = PageLink,
    nextComponent = PageLink,
    ellipsisComponent = EllipsisLink,
  }) => {
    const PageButton = pageComponent
    const CursorButton = cursorComponent
    const PreviousButton = previousComponent
    const NextButton = nextComponent
    const EllipsisButton = ellipsisComponent

    const isFirst = index === 1
    const isLast = index === pageCount

    const previousPages = index - 1
    const nextPages = pageCount - index

    if (index === undefined || pageCount === undefined || width === undefined) {
      return <Fragment />
    }

    const url = i => {
      switch (i) {
        case undefined:
        case '':
        case 0:
        case 1: {
          return `/${pathPrefix}`
        }
        default: {
          return `/${pathPrefix}/${i}`
        }
      }
    }

    const previousUrl = url(index > 2 ? index - 1 : 0)
    const nextUrl = url(index + 1)

    const renderPreviousPages = (previousPages, width) => {
      const distance = Math.min(previousPages, width)

      return (
        <Fragment>
          {previousPages > width && (
            <Fragment>
              <li>
                <PageButton url={url(1)}>1</PageButton>
              </li>
              {index > width + 2 && (
                <li>
                  <EllipsisButton />
                </li>
              )}
            </Fragment>
          )}
          {Array.from({ length: distance }, (_, i) => {
            const cursor = index - distance + i
            return (
              <li key={cursor}>
                <PageButton url={url(cursor)}>{cursor}</PageButton>
              </li>
            )
          })}
        </Fragment>
      )
    }

    const renderNextPages = (nextPages, width) => {
      const distance = Math.min(nextPages, width)

      return (
        <Fragment>
          {Array.from({ length: distance }, (_, i) => {
            const cursor = index + 1 + i
            return (
              <li key={cursor}>
                <PageButton url={url(cursor)}>{cursor}</PageButton>
              </li>
            )
          })}
          {nextPages > width && (
            <Fragment>
              {index < pageCount - width - 1 && (
                <li>
                  <EllipsisButton />
                </li>
              )}
              <li>
                <PageButton url={url(pageCount)}>{pageCount}</PageButton>
              </li>
            </Fragment>
          )}
        </Fragment>
      )
    }

    return (
      <ol className={className}>
        {renderPreviousPages(previousPages, width)}
        {!isFirst && (
          <li className="previous">
            <PreviousButton url={previousUrl}>{previousLabel}</PreviousButton>
          </li>
        )}
        <li>
          <CursorButton url={url(index)}>{index}</CursorButton>
        </li>
        {!isLast && (
          <li className="next">
            <NextButton url={nextUrl}>{nextLabel}</NextButton>
          </li>
        )}
        {renderNextPages(nextPages, width)}
      </ol>
    )
  }
)`
  display: inline-flex;
  text-align: left;
  margin-right: ${props => props.theme.dimen.tag.x};

  li {
    order: 2;

    &.previous {
      order: 1;
    }

    &.next {
      order: 3;
    }

    margin-right: ${props => props.theme.dimen.tag.x};
  }
`

const Breadcrumbs = styled(({ className, items }) => {
  return (
    <ol className={className}>
      {items.map((item, i) => {
        return (
          <li key={i}>
            <PageLink url={item.path}>{item.label}</PageLink>
          </li>
        )
      })}
    </ol>
  )
})`
  margin-right: ${props => props.theme.dimen.breadcrumb.section};

  display: none;
  ${props => props.theme.media.md`
    display: inline-block;
  `}

  li {
    display: inline-block;
    margin-right: 0;
    &:not(:first-child) {
      span {
        &:before {
          position: relative;
          left: -${props => props.theme.dimen.breadcrumb.half};
          content: '/';
          display: inline-flex;
        }
      }
    }
  }
`

const PageSelector = ({
  className,

  breadcrumbs,
  width,
  index,
  pageCount,

  nextLabel = 'Next',
  previousLabel = 'Previous',

  pathPrefix,
}) => {
  return (
    <nav className={className}>
      <Breadcrumbs items={breadcrumbs} />
      <Paginator
        index={index}
        pageCount={pageCount}
        width={width}
        nextLabel={nextLabel}
        previousLabel={previousLabel}
        pathPrefix={pathPrefix}
      />
    </nav>
  )
}

export default styled(PageSelector)`
  ${grid.unit({ size: 1 })}
`
