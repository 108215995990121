import React from 'react'

import styled from 'styled-components'
import { grid } from 'styled-components-grid'

export default styled(({ className, selector, list, aside, title }) => {
  return (
    <section className={className}>
      {title && <h1>{title}</h1>}
      <div className="breadcrumbs">{selector}</div>
      <main>{list}</main>
      <div className="breadcrumbs">{selector}</div>
      {aside && <aside>{aside}</aside>}
    </section>
  )
})`
  ${grid({})}

  justify-content: space-between;
  align-items: top;

  margin-left: auto;
  margin-right: auto;

  padding-right: ${props => props.theme.dimen.main.x};
  padding-left: ${props => props.theme.dimen.main.x};

  ${props => props.theme.media.xl`
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: ${props => props.theme.breakpoints.xl}px;
  `}

  padding-bottom: ${props => props.theme.dimen.banner._.bottom};

  ${props => props.theme.media.md`
    padding-bottom: ${props => props.theme.dimen.banner.md.bottom};
  `}

  vertical-align: top;

  > h1 {
    ${grid.unit({ size: 1 })}
    padding-top: ${props => props.theme.dimen.articleTitle.top};
    padding-bottom: ${props => props.theme.dimen.articleTitle.bottom};
    order: 1;

    font-size: ${props => props.theme.font._.h1.size};
    line-height: ${props => props.theme.font._.h1.lineHeight};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h1.size};
      line-height: ${props => props.theme.font.sm.h1.lineHeight};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h1.size};
      line-height: ${props => props.theme.font.md.h1.lineHeight};
    `}

    font-weight: ${props => props.theme.fontWeights.strong};
  }

  > .breadcrumbs {
    order: 1;
    &:last-of-type {
      order: 3;
    }
  }

  > main {
    ${props =>
      grid.unit({
        size: {
          xs: 1,
          lg: (props.aside && 5 / 7) || 1,
        },
      })}
    order: 2;
  }

  > aside {
    ${props =>
      grid.unit({
        size: {
          xs: 1,
          lg: (props.aside && 2 / 7) || 0,
        },
      })}

    order: 4;
    ${props => props.theme.media.lg`
      order: 2;
      padding-left: 2%;
    `}
  }
`
