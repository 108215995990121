import React from 'react'

import { Link } from 'gatsby'

import styled from 'styled-components'
import lodash from 'lodash'

import parameters from '@parameters'

export default styled(({ className, tags }) => {
  return (
    <ol className={className}>
      {tags.map((tag, i) => {
        return (
          <li key={i}>
            <Link to={parameters.tags.prefix(lodash.kebabCase(tag.fieldValue))}>
              <span className="tagName">{tag.fieldValue}</span>
              <span className="tagCount">{tag.edges.length}</span>
            </Link>
          </li>
        )
      })}
    </ol>
  )
})`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-top: ${props => props.theme.dimen.breadcrumb.y};

  > li {
    margin-bottom: ${props => props.theme.dimen.breadcrumb.y};
    margin-right: ${props => props.theme.dimen.breadcrumb.x};

    color: ${props => props.theme.colors.foreground('main')}
    text-decoration: none;

    a {
      background: ${props => props.theme.colors.aside.background};
      display: inline-flex;
      text-decoration: none;
      color: ${props => props.theme.colors.foreground('main')};

      height: ${props => props.theme.dimen.breadcrumb.minimumSide};
      min-width: ${props => props.theme.dimen.breadcrumb.minimumSide};

      &:hover {
        background: ${props => props.theme.colors.background('actionHover')};
        color: ${props => props.theme.colors.foreground('actionHover')};
      }

      span {
        padding: ${props => props.theme.dimen.breadcrumb.x};
      }

      span.tagName {
        font-weight: ${props => props.theme.fontWeights.strong};
        text-transform: uppercase;
      }

      span.tagCount {
        :before {
          content: " (";
        }
        :after {
          content: ")";
        }
      }
    }
  }
`
