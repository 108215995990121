import React from 'react'

import Layout from '@components/layout'
import ListPage from '@components/post/list-page'
import PageSelector from '@components/post/page-selector'
import TagIndex from '@components/post/tag-index'

export default ({
  pageContext: { breadcrumbs, keywords, tags, title },
  location,
}) => {
  const selector = <PageSelector breadcrumbs={breadcrumbs} />
  const list = <TagIndex tags={tags} />

  return (
    <Layout title={title} keywords={keywords} pathname={location.pathname}>
      <ListPage selector={selector} list={list} title={title} />
    </Layout>
  )
}
